@use '@angular/material' as mat;

@include mat.core();

$hal-app-primary: mat.define-palette(mat.$gray-palette, 900);
$hal-app-accent: mat.define-palette(mat.$gray-palette, 300);
$hal-brand-primary: mat.define-palette(mat.$red-palette, A700);

$typography-config: mat.define-typography-config(
  $headline-3: mat.define-typography-level(20px, 10px, 500),
  $button: mat.define-typography-level(15px, 15px, 500));

$hal-app-theme: mat.define-light-theme((
    color: (
      primary: $hal-app-primary,
      accent: $hal-app-accent
      ),
    typography: $typography-config
));

$hal-brand-theme: mat.define-light-theme((
  color: (
    primary: $hal-brand-primary,
    accent: $hal-brand-primary
  )
));

@include mat.all-component-colors($hal-app-theme);
@include mat.typography-hierarchy($typography-config);

app-menu {
  @include mat.all-component-colors($hal-brand-theme);
}

.mat-input-d-3 {
  @include mat.all-component-densities(-3);
}

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@600;700;800&display=swap');

.container {
  margin-top: 10px;
  margin-left: 42px;
  margin-right: 30px;
}

html, body {
  height: 100%;
}

body, label, span {
  margin: 0;
  font-family: Rubik, "Helvetica Neue", sans-serif;
}

input {
  font-family: 'Barlow', sans-serif !important;
}

.accent-button span {
  font-family: 'Barlow', sans-serif !important;
  font-weight: 700;
  letter-spacing: normal;
}

.primary-button span {
  letter-spacing: normal;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}

.mat-mdc-snack-bar-container {
  &.well-name-snackbar {
    --mdc-snackbar-container-color: red;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
    width:440px
  }
}

.apply-space {
  white-space: pre-wrap;
}

mat-divider {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  width: 97%;
}

.white-form-field {
  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: #fff !important;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #fff !important;
  }
}

